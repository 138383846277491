<template>
  <ion-list mode="ios">
    <div v-if="(currOrg != null) || !isSecurityData">
      <ion-item id="userSettings" v-if="$can(I.VIEW_USER)" lines="none" @click="userSettings()" class="cursor-pointer">
        <ion-label color="dark" mode="ios">Settings</ion-label>
        <ion-icon slot="end" name="ios-settings"></ion-icon>
      </ion-item>
      <ion-item id="dbugga" v-if="$can(I.ACCESS_DEBUGGER)" lines="none" @click="sendToDbugga()" class="cursor-pointer">
        <ion-label color="dark" mode="ios">Debugging Utilities</ion-label>
        <ion-icon slot="end" name="ios-bug"></ion-icon>
      </ion-item>
      <ion-item id="dbugga" v-if="$can(I.ACCESS_DEBUGGER)" lines="none" @click="sendToEvents()" class="cursor-pointer">
        <ion-label color="dark" mode="ios">Enter Event</ion-label>
        <ion-icon slot="end" name="ios-add-circle-outline"></ion-icon>
      </ion-item>
    </div>
    <ion-item id="userAdministration" v-if="$can(I.VIEW_USER_ROSTER)" lines="none" @click="sendToUserAdmin()" class="cursor-pointer">
        <ion-label color="dark" mode="ios">User Administration</ion-label>
        <IconPeople slot="end" class="icon-grey icon-small" />
      </ion-item>
      <ion-item id="practiceAdministration" v-if="$can(I.MODIFY_ORGANIZATION)" lines="none" @click="sendToPracticeAdmin()" class="cursor-pointer">
        <ion-label color="dark" mode="ios">Practice Administration</ion-label>
        <ion-icon slot="end" name="ios-settings"></ion-icon>
      </ion-item>
    <ion-item id="logout" lines="none" @click="logOut()" class="cursor-pointer">
      <ion-label color="dark" mode="ios">Logout</ion-label>
      <ion-icon slot="end" name="ios-logout"></ion-icon>
    </ion-item>
  </ion-list>
</template>

<script>
import { EventBus } from "@/services/Events.js";
import { addIcons } from "ionicons";
import { logOut, settings, bug, addCircleOutline } from "ionicons/icons";
import IconPeople from "@/components/Global/Icons/IconPeople";
import auth from "@/utils/Auth";
import authState from "@/utils/Auth";

addIcons({
  "ios-logout": logOut.ios,
  "ios-settings": settings.ios,
  "ios-bug": bug.ios,
  "ios-add-circle-outline": addCircleOutline.ios
});

export default {
  name: "PopoverLoggedinPerson",
  components: {
    IconPeople
  },
  data() {
    return {
      isSecurityData: auth.getSecurityData(),
      currOrg: authState.getOrgId()
    }
  },
  methods: {
    logOut() {
      
      window.localStorage.removeItem("currentPresetName");
      window.localStorage.removeItem("roster.columnState");
      window.localStorage.removeItem("roster.filterModel");

      this.$ionic.popoverController.dismiss();
      // what the hack? Yuck. No Idea why this.$router wont work here. -ww
      // ANSWER: The popovers create a new Vue scope...or something - ww & me
      this.$mypcpRouter.push({ path: "/logout" });
    },
    userSettings() {
      this.$ionic.popoverController.dismiss();
      // ditto - me
      this.$mypcpRouter.push({
        //path: "/user-settings/general-info/" + this.professionalId
        path: "/user-settings/" + this.professionalId + "/profile/"
      });
      EventBus.$emit("userSettings");
    },
    sendToUserAdmin() {
      this.$mypcpRouter.push({ name: "RosterProfessional" });
      this.$ionic.popoverController.dismiss();
    },
    sendToEvents() {
      this.$mypcpRouter.push({ name: "events" });
      this.$ionic.popoverController.dismiss();
    },
    async sendToPracticeAdmin() {
      const claims = await this.$authState.claims();
      const orgs = claims.orgs.split(",");

      if (this.$can(this.I.ADD_ORGANIZATION) || orgs.length > 1) {
        this.$mypcpRouter.push({ name: "PracticeAdmin" });
      }
      else {
        this.$mypcpRouter.push({ name: "PracticeAdminDetail", params: { id: orgs[0] } });
        //this.$mypcpRouter.push({ name: "PracticeSettings", params: { id: orgs[0] } });
      }

      this.$ionic.popoverController.dismiss();
    },
    sendToDbugga() {
      this.$mypcpRouter.push({ name: "dbugga" });
      this.$ionic.popoverController.dismiss();
    }
  }
};
</script>

<style scoped>
.icon-grey {
  fill: #7c848e;
}
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
</style>
