<template>
  <ion-row v-if="currentProfessional" class="ion-align-items-center cursor-pointer logged-in-user margin-left-ten" @click="showPopover">
    <ion-col size="auto">
      <div class="avatar">{{ currentProfessionalInitials }}</div>
    </ion-col>
    <ion-col class="desktop">
      <div class="logged-in-user-name margin-left-ten">
        {{ currentProfessional.account.title | formatDisplayName(currentProfessional.account.firstName, currentProfessional.account.lastName, currentProfessional.account.suffix) }}
      </div>
    </ion-col>
    <ion-col size="auto" class="desktop">
      <ion-icon name="ios-arrow-down"></ion-icon>
    </ion-col>
  </ion-row>
  <!-- <div slot="end" v-if="currentProfessional" class="logged-in-user cursor-pointer" @click="showPopover">
    <div class="avatar">{{ currentProfessionalInitials }}</div>
    <div class="logged-in-user-name">
      {{ currentProfessional.account.title | formatDisplayName(currentProfessional.account.firstName, currentProfessional.account.lastName, currentProfessional.account.suffix) }}
    </div>
    <div class="valign">
      <ion-icon name="ios-arrow-down"></ion-icon>
    </div>
  </div> -->
</template>

<script>
import { addIcons } from "ionicons";
import { arrowDown } from "ionicons/icons";
import PopoverLoggedinPerson from "./PopoverLoggedinPerson";

addIcons({
  "ios-arrow-down": arrowDown.ios
});

export default {
  name: "LoggedinPerson",
  computed: {
    currentProfessional() {
      return this.$store.getters["currentProfessional/get"];
    },
    currentProfessionalInitials() {
      return this.$store.getters["currentProfessional/currentProfessionalInitials"];
    }
  },
  methods: {
    showPopover: function(ev) {
      this.$ionic.popoverController
        .create({
          component: PopoverLoggedinPerson,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm",
          componentProps: {
            data: {
              professionalId: this.currentProfessional.professional.id
              //interactionId: this.$route.query.interaction_id,
            }
          }
        })
        .then(m => m.present());
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logged-in-user {
  padding: 6px;
  display: flex;
  border-radius: var(--prime-card-border-radius);
  transition: background 0.2s ease-in-out;
}
.logged-in-user:hover {
  background: var(--color-secondary-button);
}


.logged-in-user-name {
  font-size: 12px;
  font-weight: 600;
  align-self: center;
  margin-right: 10px;
  display: none;
}

@media (min-width: 1080px) {
  .logged-in-user-name {
    display: block;
  }
}

.down-arrow {
  width: 20px;
}
</style>
