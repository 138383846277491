<template>
  <div class="prime-view-container">
    <div v-if="currentProfessional" class="bottom-divider">
      <loggedin-person v-bind:currentProfessional="currentProfessional"></loggedin-person>
    </div>
    <div class="prime-full-width-container prime-dbugga-container">
      <h2 class="view-title">Debugging Utilities</h2>
      <ion-card color="black">
        <div class="prime-form-block-container">
          <div class="prime-form-block-title-row">
            <div class="prime-form-block-title">
              <h6>To</h6>
            </div>
            <div class="prime-form-block-input input-group">
              <input placeholder="Org phone number..." v-model="toPhoneNumber" id="to" name="to" class="form-control" :disabled="subscribed" />
            </div>
            <div class="prime-form-block-title">
              <h6>From</h6>
            </div>
            <div class="prime-form-block-input input-group">
              <input placeholder="Patient phone number..." v-model="fromPhoneNumber" id="from" name="from" class="form-control" :disabled="subscribed" />
            </div>
            <div class="prime-form-block-input input-group">
              <button @click="subscribe()" class="prime-button" :disabled="subscribed">Connect</button>
              <button @click="unsubscribe()" class="prime-button" :disabled="!subscribed">Disconnect</button>
            </div>
          </div>
          <p />
          <div class="prime-form-block-title-row">
            <div class="prime-form-block-title">
              <h6>Message</h6>
            </div>
            <div class="prime-form-block-input input-group">
              <textarea placeholder="Type message here..." class="form-control" rows="2" v-model="body" id="body" name="body" :disabled="!subscribed"></textarea>
            </div>
            <div class="prime-form-block-input input-group">
              <button @click="sendMessage()" class="prime-button" :disabled="!subscribed">Send Message</button>
            </div>
          </div>
          <div class="prime-form-block-title-row">
            <div class="prime-form-block-title">
              <h6>Conversation</h6>
            </div>
            <div class="prime-form-block-input input-group">
              <button @click="clearConversation()" class="prime-button">Clear</button>
            </div>
          </div>
        </div>
        <ul id="prime-dbugga-conversation-panel" class="conversation-panel">
          <li v-for="(message, key) in messages" :key="key" :class="message.direction">{{ message.item }}</li>
          <li>----------</li>
        </ul>
      </ion-card>
    </div>
  </div>
</template>

<script>
import LoggedinPerson from "@/components/Global/LoggedinPerson";
import { send as httpSend } from "@/services/Api";

export default {
  name: "Dbugga",
  components: {
    LoggedinPerson
  },
  data() {
    return {
      fromPhoneNumber: "",
      toPhoneNumber: "",
      body: "",
      subscribed: false,
      currentSubscription: "",
      organization: {}
    };
  },
  computed: {
    currentProfessional() {
      return this.$store.getters["currentProfessional/get"];
    },
    messages() {
      return this.$store.getters["dbugga/messages"];
    }
  },
  created() {
    // EventBus.$on("dbugga", data => {
    //   try {
    //     const theData = JSON.parse(data.payload);
    //     const message = theData.message;
    //     if (theData.to === this.fromPhoneNumber) {
    //       // this.addToConversation(message, "dbugga-incoming");
    //     }
    //   } catch (e) {
    //     khanSolo.log(e);
    //   }
    // });
  },
  async mounted() {
    this.getOrganization();
    this.setInitialValues();
  },
  watch: {
    currentProfessional() {
      this.getOrganization();
    }
  },
  beforeDestroy() {
    if (this.subscribed) {
      this.$store.dispatch("websocket/unsubscribe", this.currentSubscription);
    }
  },
  methods: {
    getOrganization: async function() {
      if (!this.currentProfessional) {
        return;
      }
      const orgId = this.currentProfessional.professional.organizationId;
      const method = "get";
      const path = document.config.organization + orgId;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.organization = result.data;
          this.toPhoneNumber = this.organization.phoneNumber;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not get current professional's organization. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
        });
    },
    subscribe: async function() {
      if (this.subscribed) {
        await this.unsubscribe(this.currentSubscription);
      }
      let subscription = { type: "dbugga", data: "all" };

      this.$store.dispatch("websocket/subscribe", subscription);
      this.subscribed = true;
      this.currentSubscription = subscription;
      this.storeValues();
    },
    unsubscribe: async function() {
      if (!this.subscribed) {
        return;
      }

      this.$store.dispatch("websocket/unsubscribe", this.currentSubscription);
      this.currentSubscription = "";
      this.subscribed = false;
    },
    clearConversation: async function() {
      this.$store.commit("dbugga/clearMessages");
    },
    addToConversation(item, direction) {
      var root = document.querySelector("#prime-dbugga-conversation-panel");
      var el = document.createElement("li");
      el.className = direction;
      el.innerText = item;
      root.appendChild(el);
    },
    sendMessage: async function() {
      this.loading = true;
      const method = "post";
      const path = document.config.dbuggaWebhookUrl;

      const payload = "From=" + encodeURIComponent(this.fromPhoneNumber) + "&To=" + encodeURIComponent(this.toPhoneNumber) + "&Body=" + encodeURIComponent(this.body) + "&provider=dbugga";

      httpSend({ path, method, authToken: true, headers: { "Content-Type": "application/x-www-form-urlencoded" }, payload })
        .then(() => {
          this.$store.commit("dbugga/messages", { direction: "dbugga-outgoing", item: this.body });
          // this.addToConversation(this.body, "dbugga-outgoing");
          this.body = "";
          this.$ionic.toastController
            .create({
              header: "Success!",
              message: "Message has been sent",
              duration: 1000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          this.loading = false;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Error",
              message: "Could not send message.",
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());

          khanSolo.log(error);
          this.loading = false;
        });
    },
    storeValues() {
      var from = this.fromPhoneNumber;
      localStorage.setItem("from", from);
    },
    setInitialValues() {
      const from = localStorage.getItem("from");
      this.fromPhoneNumber = from;
    }
  }
};
</script>

<style>
label {
  margin-right: 5px;
  margin-left: 5px;
}
.dbugga-incoming {
  color: yellow;
}
.dbugga-outgoing {
  color: #b19cd9;
}
.messageText {
  color: black;
  margin-left: 5px;
}
/* -------------------------------- */
/* Patient Roster ----------------- */
/* -------------------------------- */
.prime-dbugga-options-button-container {
  height: 60px;
}
.conversation-panel {
  list-style: none;
  background-color: black;
  color: white;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.prime-dbugga-details {
  background-color: black;
  color: white;
  padding-left: 5px;
}
.prime-dbugga-container .form-control:disabled {
  color: white;
  background-color: black;
}

.prime-dbugga-details textarea {
  color: black;
  width: 300px;
}

.prime-dbugga-container button.prime-button {
  padding-left: 14px;
  padding-right: 14px;
  font-size: 14px;
  background-color: white;
  color: black;
  margin-left: 6px;
  height: 30px;
  font-weight: normal;
  margin-top: 5px;
}
.prime-dbugga-container button.prime-button:disabled {
  color: white;
  background-color: black;
}
.prime-dbugga-container div.prime-form-block-title {
  margin-left: 3px;
  margin-right: 3px;
}

.prime-dbugga-container ion-card {
  margin: 20px 0 !important;
  padding: 0;
  /* background-color: black; */
  color: white;
}
.prime-dbugga-container ion-card ion-card-header {
  padding: 20px 20px 10px 20px;
  /* background-color: black; */
  color: white;
}
.prime-dbugga-container ion-card ion-card-header * {
  margin-right: 10px;
  /* background-color: black; */
  color: white;
}

.prime-dbugga-container .prime-form-block-container {
  width: 400px;
  margin-left: 5px;
}
</style>
